html.dark {
  background-color: rgb(15, 23, 42);
}

.blend-difference {
  mix-blend-mode: difference;
}

.blend-multiply {
  mix-blend-mode: multiply;
}
